<template>
  <div>
    <div class="wrapper d-flex align-items-stretch">
      <MainMenu />
      <div id="content" class="p-4 p-md-5 pt-5">
        <h1 class="text-center">{{ $t("Copla.Views.StatsSRT.Title") }}</h1>
        <form @submit="checkForm" id="formulario">
          <div class="border rounded secciones">
            <div class="form-group row">
              <label for="file" class="col-2 col-form-label text-center">{{
                $t("Copla.Common.Form.SRTFile")
              }}</label>
              <div class="col-10">
                <input
                  type="file"
                  class="form-control"
                  id="file"
                  name="file"
                  ref="file"
                  v-on:change="handleFileUpload()"
                  accept=".srt"
                  required
                />
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <div class="text-center">
                <button
                  type="submit"
                  id="comenzar"
                  name="comenzar"
                  class="btn btn-success mr-2"
                >
                  {{ $t("Copla.Common.Buttons.Stats") }}
                </button>
                <button
                  type="button"
                  id="descarga"
                  name="descarga"
                  class="btn btn-info"
                  v-on:click="downloadCleanText"
                  v-if="cleanText.length > 0"
                >
                  {{ $t("Copla.Common.Buttons.Transcription") }}
                </button>
              </div>
            </div>
          </div>
        </form>
        <div v-if="calculated == 1" class="mt-5">
          <table class="table table-bordered table-sm">
            <caption>
              {{
                $t("Copla.Views.StatsSRT.Table.Time")
              }}
              :
              {{
                duration.toFixed(3)
              }}
              {{
                $t("Copla.Views.StatsSRT.Table.Sec")
              }}
              /
              {{
                $t("Copla.Views.StatsSRT.Table.Subtitles")
              }}
              {{
                subs
              }}
            </caption>
            <thead class="thead-dark">
              <tr>
                <th></th>
                <th>{{ $t("Copla.Views.StatsSRT.Table.Min") }}</th>
                <th>{{ $t("Copla.Views.StatsSRT.Table.Max") }}</th>
                <th>{{ $t("Copla.Views.StatsSRT.Table.Mean") }}</th>
                <th>{{ $t("Copla.Views.StatsSRT.Table.Total") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ $t("Copla.Views.StatsSRT.Table.Words") }}</td>
                <td>{{ words.min }}</td>
                <td>{{ words.max }}</td>
                <td>{{ words.average.toFixed(2) }}</td>
                <td>{{ words.total }}</td>
              </tr>
              <tr>
                <td>{{ $t("Copla.Views.StatsSRT.Table.Characters") }}</td>
                <td>{{ chars.min }}</td>
                <td>{{ chars.max }}</td>
                <td>{{ chars.average.toFixed(2) }}</td>
                <td>{{ chars.total }}</td>
              </tr>
              <tr>
                <td>{{ $t("Copla.Views.StatsSRT.Table.CPS") }}</td>
                <td>{{ cps.min.toFixed(2) }}</td>
                <td>{{ cps.max.toFixed(2) }}</td>
                <td>{{ cps.average.toFixed(2) }}</td>
                <td>-</td>
              </tr>
              <tr>
                <td>{{ $t("Copla.Views.StatsSRT.Table.WPM") }}</td>
                <td>{{ wpm.min.toFixed(2) }}</td>
                <td>{{ wpm.max.toFixed(2) }}</td>
                <td>{{ wpm.average.toFixed(2) }}</td>
                <td>-</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainMenu from "../components/MainMenu";
export default {
  name: "StatsSRT",
  components: {
    MainMenu
  },
  data() {
    return {
      file: "",
      base_url: process.env.VUE_APP_BASE_URL,
      chars: { min: 0, max: 0, total: 0, average: 0 },
      words: { min: 0, max: 0, total: 0, average: 0 },
      times: {
        start: 0,
        end: 0,
        min: 0,
        max: 0
      },
      subs: 0,
      current: { start: 0, end: 0, words: 0, chars: 0 },
      cps: { min: 0, average: 0, max: 0 },
      wpm: { min: 0, average: 0, max: 0 },
      calculated: 0,
      cleanText: ""
    };
  },
  computed: {
    duration: function() {
      return this.times.end - this.times.start;
    }
  },
  methods: {
    init: function() {
      this.chars = { min: 0, max: 0, total: 0, average: 0 };
      this.words = { min: 0, max: 0, total: 0, average: 0 };
      this.times = { start: 0, end: 0, min: 0, max: 0 };
      this.subs = 0;
      this.current = { start: 0, end: 0, words: 0, chars: 0 };
      this.cps = { min: 0, average: 0, max: 0 };
      this.wpm = { min: 0, average: 0, max: 0 };
      this.calculated = 0;
      this.cleanText = "";
    },
    downloadCleanText: function() {
      let file = "transcription.txt";
      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(this.cleanText)
      );
      element.setAttribute("download", file);
      element.style.display = "none";
      element.click();
    },
    handleFileUpload: function() {
      this.file = this.$refs.file.files[0];
      this.init();
    },
    checkForm: function(e) {
      e.preventDefault();
      this.init();
      let self = this;
      var reader = new FileReader();
      var type = "blank";
      reader.onload = function() {
        var lines = this.result.split("\n");
        for (var line = 0; line < lines.length; line++) {
          type = self.parserLine(lines[line].trim(), type);
        }
        if (type == "text") {
          self.updateStatsSubtitle();
        }
        self.cps.average = self.cps.average / self.subs;
        self.chars.average = self.chars.total / self.subs;
        self.words.average = self.words.total / self.subs;
        self.wpm.average = self.wpm.average / self.subs;
        self.calculated = 1;
      };
      reader.readAsText(self.file);
    },
    parserLine(line, type) {
      let cleanLine = "";
      switch (type) {
        case "blank":
          type = "number";
          break;
        case "text":
          if (line.length == 0) {
            this.updateStatsSubtitle();
            type = "blank";
          } else {
            type = "text";
            cleanLine = this.removeTags(line);
            this.current.chars = this.current.chars + cleanLine.length;
            this.current.words =
              this.current.words + cleanLine.split(" ").length;
            this.cleanText += " " + cleanLine;
          }
          break;
        case "number":
          type = "time";
          var times = line.split(" --> ");
          this.current.start = this.srtTimeToSeconds(times[0]);
          this.current.end = this.srtTimeToSeconds(times[1]);
          this.subs++;
          break;
        case "time":
          type = "text";
          cleanLine = this.removeTags(line);
          this.current.chars = this.current.chars + cleanLine.length;
          this.current.words = this.current.words + cleanLine.split(" ").length;
          this.cleanText += " " + cleanLine;
          break;
      }
      return type;
    },
    removeTags(txt) {
      var rex = /(<([^>]+)>)/gi;
      return txt.replace(rex, "");
    },
    srtTimeToSeconds(srtTime) {
      var times = srtTime.split(":");
      var seconds = 3600 * parseInt(times[0]) + 60 * parseInt(times[1]);
      var miliseconds = times[2].split(",");
      seconds =
        seconds + parseInt(miliseconds[0]) + parseInt(miliseconds[1]) / 1000;
      return seconds;
    },
    updateStatsSubtitle() {
      this.chars.total = this.chars.total + this.current.chars;
      if (this.current.chars < this.chars.min || this.chars.min == 0) {
        this.chars.min = this.current.chars;
      }
      if (this.current.chars > this.chars.max) {
        this.chars.max = this.current.chars;
      }
      this.words.total = this.words.total + this.current.words;
      if (this.current.words < this.words.min || this.words.min == 0) {
        this.words.min = this.current.words;
      }
      if (this.current.words > this.words.max) {
        this.words.max = this.current.words;
      }
      if (this.times.start == 0) {
        this.times.start = this.current.start;
      }
      this.times.end = this.current.end;
      if (
        this.current.end - this.current.start < this.times.min ||
        this.times.min == 0
      ) {
        this.current.min = this.current.end - this.current.start;
      }
      if (this.current.end - this.current.start > this.times.max) {
        this.current.max = this.current.end - this.current.start;
      }
      let currentCPS =
        this.current.chars / (this.current.end - this.current.start);
      this.cps.average = this.cps.average + currentCPS;
      if (currentCPS < this.cps.min || this.cps.min == 0) {
        this.cps.min = currentCPS;
      }
      if (currentCPS > this.cps.max) {
        this.cps.max = currentCPS;
      }
      let currentWPM =
        this.current.words / ((this.current.end - this.current.start) / 60);
      this.wpm.average = this.wpm.average + currentWPM;
      if (currentWPM < this.wpm.min || this.wpm.min == 0) {
        this.wpm.min = currentWPM;
      }
      if (currentWPM > this.wpm.max) {
        this.wpm.max = currentWPM;
      }
      this.current.chars = 0;
      this.current.words = 0;
      this.current.start = 0;
      this.current.end = 0;
    }
  }
};
</script>

<style scoped>
.wrapper {
  width: 100%;
}

h1 {
  font-size: 1.8em;
  font-weight: bold;
}
.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

#content {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-color: white;
}
</style>
